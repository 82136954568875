.f-menu-customisation {
  .menu-customisation__image {
    width: 100%;
    height: 300px;
    background: center center / contain no-repeat;
  }

  .menu-customisation__section {
    margin-bottom: 1.25rem;
  }
}
