.c-restaurant-header {
  .restaurant-header__banner {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .restaurant-header__banner--cover {
      height: 18rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-shadow: inset 1px -7px 59px 20px rgba(0, 0, 0, 0.45);
      }

      img {
        height: 18rem;
        object-fit: cover;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
        width: 100%;
      }

      h1 {
        color: #fff;
        font-size: 3rem;
        font-weight: 700;
        margin: 0.5rem 1.25rem;
        position: absolute;
        left: 0;
        bottom: 0;

        @media screen and (max-width: 1024px) {
          font-size: 2.25rem;
        }

        @media screen and (max-width: 768px) {
          font-size: 1.875rem;
        }
      }
    }

    .restaurant-header__banner--adress {
      background-color: rgba(248, 248, 248, 1);
      padding: 1rem;

      span {
        color: rgba(102, 102, 102, 1);
      }
    }
  }

  .restaurant-header__categories {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 20;

    &--mobile {
      background-color: #3c9;
      color: #fff;
      font-weight: bold;
      border-radius: 0.375rem;
      cursor: pointer;

      .cart-count {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2ba27a;
        border-radius: 0.375rem;
        padding: 0.1rem 0.6rem;
      }
    }
  }

  .restaurant-header__category {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    outline: 0;
    text-decoration: none;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: rgba(248, 248, 248, 1);
    color: #000;
    font-weight: 700;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    height: 43px;
    border-radius: 9999px;
    border: 0;
    cursor: pointer;

    &.active {
      background-color: var(--ui-primary);
      color: #fff;
    }
  }

  .restaurant-header__category_more {
    border-radius: 0.5rem;
    --bg-opacity: 1;
    background-color: rgba(248, 248, 248, var(--bg-opacity));
    height: 21rem;
    margin-top: 4rem;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    z-index: 100;
    text-align: center;
    width: 14rem;
    list-style: none;

    & > a.active span {
      color: rgba(51, 204, 153, 1);
    }
  }

  .card-lists__title {
    font-size: 1.875rem;
    font-weight: 700;
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: rgba(34, 34, 34, 1);
  }

  .card-lists {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .card-list {
    --border-opacity: 1;
    border-radius: 0.5rem;
    cursor: pointer;
    border-width: 1px;
    border-color: rgba(237, 242, 247, var(--border-opacity));
    background-color: rgba(255, 255, 255, var(--bg-opacity));

    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }

  .card-list__header {
    height: 10rem;
    width: 100%;
  }

  .card-list__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1.25rem;
    position: relative;
    width: 100%;
  }

  .card-list__infos {
    h3 {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
      color: rgba(34, 34, 34, 1);
      letter-spacing: 0.025em;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    .card-list__desc {
      color: rgba(102, 102, 102, 1);
      font-size: 0.875rem;
      display: block;
    }

    .card-list__price {
      font-size: 0.875rem;
      margin-top: 0.5rem;
      display: block;
      font-family: OpenSans, sans-serif;
    }
  }

  .card-list__illustration {
    display: flex;
    flex-shrink: 0;
    height: 8rem;
    padding: 1.25rem;
    width: 8rem;
    margin-right: 0;
    margin-left: calc(1.5rem);
  }

  .card-list__img {
    width: 100%;
    height: 100%;
    background: #f7f7f7 no-repeat center center;
    background-size: contain;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }
}
